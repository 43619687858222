export default [
  {
    path: '/admins',
    name: 'admins',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/admin/admin-list/AdminList'),
    meta: {
      pageTitle: 'Admin users',
    },
  },
  {
    path: '/admin/:id',
    name: 'admin-edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/admin/admin-edit/AdminEdit'),
    meta: {
      pageTitle: 'Admin user account',
      breadcrumb: [
        {
          text: 'Admin',
          to: '/admins',
        },
        {
          text: 'Edit admin',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users',
    name: 'users',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/user/user-list/UserList'),
    meta: {
      pageTitle: 'Users',
    },
  },
  {
    path: '/user/:id',
    name: 'user-edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/user/user-edit/UserEdit'),
    meta: {
      pageTitle: 'User account',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Edit user',
          active: true,
        },
      ],
    },
  },
  {
    path: '/encyclopedia',
    name: 'encyclopedia',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/encyclopedia/Encyclopedia'),
    meta: {
      pageTitle: 'Encyclopedia',
    },
  },
  {
    path: '/presentations',
    name: 'presentations',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/presentations/Presentations'),
    meta: {
      pageTitle: 'Presentations',
    },
  },

  {
    path: '/encyclopedia/new',
    name: 'add-new-encyclopedia',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/encyclopedia/EncyclopediaAddNew'),
    meta: {
      pageTitle: 'Encyclopedia',
      breadcrumb: [
        {
          text: 'Encyclopedia',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/encyclopedia/:id',
    name: 'encyclopedia-edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/encyclopedia/EncyclopediaEdit'),
    meta: {
      pageTitle: 'Encyclopedia',
      breadcrumb: [
        {
          text: 'Encyclopedia',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  // Content
  {
    path: '/cms/category',
    name: 'cms-category',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/category/CategoryList.vue'),
    meta: {
      pageTitle: 'Categories',
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Categories',
          active: true,
        },
      ],
    },
  },

  {
    path: '/cms/category/sub-category/:id',
    name: 'cms-sub-category',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/subCategory/SubCategoryList'),
    meta: {
      pageTitle: 'Subcategory',
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Categories',
          to: '/cms/category',
        },
        {
          text: 'Subcategory',
          active: true,
        },
      ],
    },
  },

  {
    path: '/cms/attribute',
    name: 'cms-attribute',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/attribute/AttributeList.vue'),
    meta: {
      pageTitle: 'Attributes',
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Attributes',
          active: true,
        },
      ],
    },
  },

  {
    path: '/cms/tag',
    name: 'cms-tag',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/tag/TagList.vue'),
    meta: {
      pageTitle: 'Tags',
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Tags',
          active: true,
        },
      ],
    },
  },

  {
    path: '/cms/history-period',
    name: 'cms-history-period',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/history-period/HistoryPeriodList.vue'),
    meta: {
      pageTitle: 'History period',
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'History period',
          active: true,
        },
      ],
    },
  },

  {
    path: '/cms/region',
    name: 'cms-region',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/region/RegionList.vue'),
    meta: {
      pageTitle: 'Regions',
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Regions',
          active: true,
        },
      ],
    },
  },

  {
    path: '/cms/country',
    name: 'cms-country',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/country/CountryList'),
    meta: {
      pageTitle: 'School countries',
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Countries',
          active: true,
        },
      ],
    },
  },

  {
    path: '/cms/encik-country',
    name: 'cms-encik-country',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/ecik-country/EncikCountryList'),
    meta: {
      pageTitle: 'Encyclopedia countries',
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Encyclopedia countries',
          active: true,
        },
      ],
    },
  },

  {
    path: '/cms/historical-country',
    name: 'cms-historical-country',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/historicalCountry/HistoricalCountryList'),
    meta: {
      pageTitle: 'Historical countries',
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Historical countries',
          active: true,
        },
      ],
    },
  },

  {
    path: '/cms/subject',
    name: 'cms-subject',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/subject/SubjectList'),
    meta: {
      pageTitle: 'Subjects',
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Subjects',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cms/subject/settings',
    name: 'cms-subject-settings',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/subject/subjectSettings/SubjectSettingsList.vue'),
    meta: {
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Subjects',
          active: true,
        },
        {
          text: 'Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cms/grade',
    name: 'cms-grade',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/grade/GradeList'),
    meta: {
      pageTitle: 'Grade',
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Grade',
          active: true,
        },
      ],
    },
  },

  {
    path: '/cms/state',
    name: 'cms-state',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/state/StateList.vue'),
    meta: {
      pageTitle: 'State',
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'State',
          active: true,
        },
      ],
    },
  },

  {
    path: '/cms/city',
    name: 'cms-city',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/city/CityList'),
    meta: {
      pageTitle: 'School communities',
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'School communities',
          active: true,
        },
      ],
    },
  },

  {
    path: '/cms/school',
    name: 'cms-school',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/school/SchoolList'),
    meta: {
      pageTitle: 'Schools',
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Schools',
          active: true,
        },
      ],
    },
  },
  {
    path: '/analytics',
    name: 'analytics',
    redirect: '/analytics/users',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/analytics/UsersAnalytics'),
    meta: {
      pageTitle: 'Analytics',
      tabs: [
        {
          title: 'Users',
          route: '/analytics/users',
        },
        {
          title: 'Schools',
          route: '/analytics/schools',
        },
        {
          title: 'Tables',
          route: '/analytics/tables',
        },
      ],
    },
  },
  {
    path: '/analytics/users',
    name: 'analyticsUsers',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/analytics/UsersAnalytics'),
    meta: {
      pageTitle: 'Analytics',
      tabs: [
        {
          title: 'Users',
          route: '/analytics/users',
        },
        {
          title: 'Schools',
          route: '/analytics/schools',
        },
        {
          title: 'Tables',
          route: '/analytics/tables',
        },
      ],
    },
  },
  {
    path: '/analytics/schools',
    name: 'analyticsSchools',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/analytics/SchoolsAnalytics'),
    meta: {
      pageTitle: 'Analytics',
      tabs: [
        {
          title: 'Users',
          route: '/analytics/users',
        },
        {
          title: 'Schools',
          route: '/analytics/schools',
        },
        {
          title: 'Tables',
          route: '/analytics/tables',
        },
      ],
    },
  },
  {
    path: '/analytics/tables',
    name: 'analyticsTables',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/analytics/CustomTableWrapper.vue'),
    meta: {
      pageTitle: 'Tables',
      tabs: [
        {
          title: 'Users',
          route: '/analytics/users',
        },
        {
          title: 'Schools',
          route: '/analytics/schools',
        },
        {
          title: 'Tables',
          route: '/analytics/tables',
        },
      ],
    },
  },
  {
    path: '/topics',
    name: 'topics',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/topics/Topics'),
    meta: {
      pageTitle: 'Topics & Lessons',
    },
  },
  {
    path: '/gis/editor',
    name: 'editor',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/gis/GIS'),
    meta: {
      pageTitle: 'GIS Editor',
    },
  },
  {
    path: '/gis/style-editor',
    name: 'style',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/gis-style/ReactWrapper.vue'),
    meta: {
      pageTitle: 'GIS Style Editor',
    },
  },
]
